import * as Redux from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizations } from "../../hubs/UserAccountManagementController.hub";
import { Organization } from "./models";

export interface SliceProps {
	status: "initial" | "loading" | "loaded" | "error",
	organizations: Organization[]
}

export function makeInitialState(): SliceProps {
	return {
		status: "initial",
		organizations: []
	};
}

export const thunks = {
	fetchOrganizations: createAsyncThunk(
		"organizations/fetchOrganizations",
		async () => getOrganizations()
	)
}

export const slice = Redux.createSlice({
	name: "organizations",
	initialState: makeInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(thunks.fetchOrganizations.pending, (state) => {
			state.status = "loading";
		});
		builder.addCase(thunks.fetchOrganizations.fulfilled, (state, action) => {
			if (action.payload.status === "2xx") {
				state.organizations = action.payload.organizations;
				state.status = "loaded";
			}
		});
		builder.addCase(thunks.fetchOrganizations.rejected, (state) => {
			state.status = "error";
		});
	}
});
