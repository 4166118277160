/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Store } from '../store';
import { slices } from '../slices';

/**
 * Calls a method once when the component is mounted,
 * and another when unmounted.
 * @param onComponentMounted
 * @param onComponentUnmounted
 */
export function useComponentLifecycle(
	onComponentMounted?: () => void,
	onComponentUnmounted?: () => void
): void {
	React.useEffect(() => {
		onComponentMounted?.();

		return () => onComponentUnmounted?.();
	}, []);
}

/** Hook that fetches the store and dispatch props related to the current page.  */
export function useSlice<TSliceName extends SliceName>(
	name: TSliceName
): PageProps<TSliceName> {
	// Get the state for this slice.
	const state = useAppSelector((state) => state[name]);

	const sliceFile = slices[name];
	const thunks: SliceThunks<TSliceName> = sliceFile.thunks;
	const actions: SliceActions<TSliceName> = sliceFile.slice.actions;

	// Get dispatchable versions of the actions.
	const dispatch = useDispatch();

	const thunksAndActions = {
		...thunks,
		...actions,
	};

	const connectedActionsBuilder = {};
	for (const [name, action] of Object.entries(thunksAndActions)) {
		(connectedActionsBuilder as any)[name] = (payload: any) =>
			dispatch(action(payload));
	}
	const connectedActions = connectedActionsBuilder as PageActions<TSliceName>;

	const result: PageProps<TSliceName> = {
		...state,
		...connectedActions,
	};

	return result;
}

export type StoreState = ReturnType<Store['getState']>;
export type PageActions<TSliceName extends SliceName> = MappedDispatches<
	SliceActions<TSliceName>
> &
	MappedDispatches<SliceThunks<TSliceName>>;
export type PageProps<TSliceName extends SliceName> = StoreState[TSliceName] &
	PageActions<TSliceName>;

type SliceFiles = typeof slices;
type SliceName = keyof SliceFiles;
type MappedDispatches<T extends { [idx: string]: (...args: any[]) => any }> = {
	[K in keyof T]: Parameters<T[K]>[0] extends undefined
		? () => void
		: (args: Parameters<T[K]>[0]) => void;
};
type SliceActions<TSliceName extends SliceName> =
	SliceFiles[TSliceName]['slice']['actions'];
type SliceThunks<TSliceName extends SliceName> =
	SliceFiles[TSliceName]['thunks'];

const useAppSelector: TypedUseSelectorHook<StoreState> = useSelector;
