import * as Redux from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetLinkedAccounts } from '../hubs/AccountController.hub';
import { createThunk } from '../util/slice';
import { filterAccounts, getStateParamsFromQueryString, mapByApplicationCode } from './homeUtility';
import { LinkedAccount } from './models';

export interface StoreProps {
	isLoading: boolean;
	linkedAccounts: LinkedAccount[];
	applicationMappedAccounts: LinkedAccount[][];
	filteredAccounts: LinkedAccount[][];
	searchText: string;
	applicationFilter: string;
	isAccountLinkingComplete: boolean;
	wasErrorLoadingLinkedAccounts: boolean;
}

export function makeInitialState(): StoreProps {
	const initialData = (window as any)['initialData'];
	const initialAccounts: LinkedAccount[] = [];
	const mappedAccounts = mapByApplicationCode(initialAccounts);
	return {
		isLoading: true,
		linkedAccounts: [],
		applicationMappedAccounts: mappedAccounts,
		filteredAccounts: mappedAccounts,
		searchText: '',
		applicationFilter: 'All',
		isAccountLinkingComplete: initialData?.isAccountLinkingComplete ?? true,
		wasErrorLoadingLinkedAccounts: false,
	};
}

export const thunks = {
	updateSearchText: createThunk<string>(async (searchText, dispatch) => {
		dispatch(slice.actions.updateSearchText(searchText));
	}),
	updateApplicationFilter: createThunk<string>(async (applicationCode, dispatch) => {
		dispatch(slice.actions.updateApplicationFilter(applicationCode));
	}),
	fetchLinkedAccounts: createAsyncThunk(
		'account/linkedAccounts',
		async () => {
			const stateParam = getStateParamsFromQueryString();
			return GetLinkedAccounts(stateParam);
		}
	),
};

export const slice = Redux.createSlice({
	name: 'home',
	initialState: makeInitialState(),
	reducers: {
		updateSearchText(state, action: Redux.PayloadAction<string>) {
			state.filteredAccounts = filterAccounts(action.payload, state.applicationFilter, state.linkedAccounts);
			state.searchText = action.payload;
		}, updateApplicationFilter(state, action: Redux.PayloadAction<string>) {
			state.applicationFilter = action.payload;
			state.applicationMappedAccounts = mapByApplicationCode(state.linkedAccounts);
			state.filteredAccounts = filterAccounts(state.searchText, action.payload, state.linkedAccounts);
		}
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.fetchLinkedAccounts.pending, (state) => {
			state.isLoading = true;
		}),
			builder.addCase(thunks.fetchLinkedAccounts.fulfilled, (state, action) => {
				if (action.payload.status === '2xx') {
					const linkedAccounts = action.payload.body.linkedAccounts;
					state.linkedAccounts = linkedAccounts;

					if (linkedAccounts.length === 1) {
						window.location.replace(linkedAccounts[0].redirectUrl);
					}
					else {
						state.filteredAccounts = filterAccounts(state.searchText, state.applicationFilter, linkedAccounts);
						state.applicationMappedAccounts = mapByApplicationCode(linkedAccounts);
						state.wasErrorLoadingLinkedAccounts = action.payload.body.errorLoadingLinkedAccounts;
						state.isLoading = false;
					}

				}
			}),
			builder.addCase(thunks.fetchLinkedAccounts.rejected, (state) => {
				state.isLoading = false;
				state.linkedAccounts = [];
				state.wasErrorLoadingLinkedAccounts = true;
			});
	}
});