import {
    Button,
    Card,
    SearchSelectInput,
    Text,
    MdArrowForward,
    MdAdd,
    Note,
    LoadingDataState,
} from "@dsx/react";

import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageProps, useSlice } from "../../util/page";
import siteStyles from "../site.module.css";
import { thunks } from "./organizations.slice";
import pageStyles from "./organizations.module.css"

export function Organizations(props: PageProps<"organizations">) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [selectedOrganization, setSelectedOrganization] = React.useState("");
    
	useEffect(() => {
		dispatch(thunks.fetchOrganizations());
	}, [dispatch]);

    const organizations = props.organizations?.map((org) => {
        return {
            label: `${org.name} | ${org.displayName}`,
            value: org.name
        }
    }) ?? [];

    const loadingState: { initial: LoadingDataState; loading: LoadingDataState; loaded: LoadingDataState, error: LoadingDataState } = {
        initial: "initial",
        loading: "loading",
        loaded: "success",
        error: "failure",
    };

    return (
        <div className={siteStyles["block-container"]}>
            {props.status === "error" && (
                <Note variant="danger">
                    There was an error loading the list of organizations. Please try again later.
                </Note>
            )}
            <Card>
                <Text variant="headline-1">Select Organization</Text>
                <br></br>
                <Text>
                    gravida neque convallis a cras semper auctor neque vitae tempus quam
                    pellentesque nec nam aliquam sem et tortor consequat id porta nibh
                    venenatis cras sed felis eget velit aliquet sagittis id consectetur purus
                    ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt
                    vitae semper quis lectus nulla at volutpat
                </Text>
            </Card>
            <Card>
                <div className={siteStyles["card-content"]}>
                    <label htmlFor="select-organization-input">Select an Organization</label>
                    <SearchSelectInput
                        id="select-organization-input"
                        options={organizations}
                        loadingState={loadingState[props.status]}
                        variant={props.status === "error" ? "danger" : "default"}
                        disabled={props.status !== "loaded"}
                        onChange={org => setSelectedOrganization(org)}
                        value={selectedOrganization}
                    />
                    <Text variant="body-extra-small">
                        Can&apos;t find the organization you&apos;re looking for?
                        <br></br>
                        Add it with the <strong>Add Organization</strong> button below.
                    </Text>
                    <div className={siteStyles["button-container"]}>
                        <Button
                            variant="primary"
                            iconPosition="end"
                            icon={MdArrowForward}
                            disabled={selectedOrganization === ""}
                            onClick={() => navigate(`/user-account-management/organizations/${selectedOrganization}/users`)}
                        >
                            View Users
                        </Button>
                        <Button
                            variant="ui-control"
                            iconPosition="start"
                            icon={MdAdd}
                            onClick={() => navigate("/user-account-management/organizations/add")}
                        >
                            Add Organization
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export function OrganizationsConnected() {
	const storeProps = useSlice("organizations");
	return <Organizations {...storeProps} />;
}
