import * as React from 'react';

import { DsLogo } from '@dsx-react/logo';
import {
	Button,
	combineMessages,
	Link,
	Modal,
	NavBar,
	NavBarLink,
	Text,
	useMessageFormatter,
} from '@dsx/react';
import commonMessages from '../common.intl';
import { PageProps, useSlice } from '../util/page';
import { useClassName } from '../util/theme';
import { useAuth } from '../auth/auth-context';
import styles from './shell.module.css';

import pageMessages from './intl';
export type ShellOwnProps = React.PropsWithChildren<unknown>;

export function Shell(props: PageProps<'shell'> & ShellOwnProps) {
	useMessageFormatter(
		combineMessages(commonMessages, pageMessages)
	);

	const { isAuthenticated } = useAuth();
	const currentYear = new Date().getFullYear();
	const { isErrorModalOpen, errorModalDetails, unhandledErrorDismissed } = props;

	let homeHref = '/';
	let logoutHref = '/apps/logout';

	if (window.location.pathname.startsWith('/user-account-management')) {
		homeHref += 'user-account-management';
		logoutHref += '?returnTo=/user-account-management';
	}

	return (
		<div className={styles["content"]}>
			<WereSorryModal
				show={isErrorModalOpen}
				details={errorModalDetails}
				onCancel={unhandledErrorDismissed}
			/>
			<NavBar
				href={homeHref}
				logo={DsLogo}
				utility={
					<>
						{isAuthenticated && <NavBarLink href={logoutHref} hrefType='external'>{'Logout'}</NavBarLink>}
					</>
				}
			/>
			<main className={styles['main']}>{props.children}</main>
			<footer className={styles["footer"]}>
				<span className={styles["footer-text"]}>&copy; 2022 - {currentYear} Direct Supply, Inc. All rights reserved.</span>
				<div>
					<ul>
						<li><a href="https://www.directsupply.com/legal/privacy-policy" target="_blank" rel="external noreferrer">Privacy Policy</a></li>
						<li><a href="https://www.directsupply.com/legal/terms-of-use/" target="_blank" rel="external noreferrer">Terms of Use</a></li>
						<li><a href="https://www.directsupply.com/legal/cookie-policy" target="_blank" rel="external noreferrer">Cookie Policy</a></li>
						<li><a href="https://www.directsupply.com/legal/privacy-policy#california-collection-notice" target="_blank" rel="external noreferrer">California Collection Notice</a></li>
						<li><Link onClick={() => (window as any).OneTrust.ToggleInfoDisplay()}>Your Privacy Choices <img src="https://branding.directsupply.com/assets/dsi/legal/opt-out.png" /></Link></li>
					</ul>
				</div>
			</footer>
		</div>
	);
}

export interface WereSorryModalProps {
	show: boolean;
	onCancel(): void;
	details: string | null;
}

export function WereSorryModal(props: WereSorryModalProps) {
	const { formatMessage } = useMessageFormatter(
		combineMessages(commonMessages, pageMessages)
	);
	const detailsEl = props.details ? (
		<div className={styles['detailed-info']}>
			<div>{formatMessage('Detailed information:')}</div>
			<pre className={styles['detailed-info-details']}>
				<code>{props.details}</code>
			</pre>
		</div>
	) : null;

	const modalHeaderEl = (
		<Text variant="headline-1">{formatMessage("We're sorry")}</Text>
	);
	const modalFooterEl = (
		<Button onClick={props.onCancel} variant="primary">
			{formatMessage('OK')}
		</Button>
	);

	return (
		<Modal
			label={"We're sorry"}
			isOpen={props.show}
			onClose={props.onCancel}
			header={modalHeaderEl}
			footer={modalFooterEl}
		>
			<div>
				{formatMessage(
					'An unexpected error occurred in our system. Please try your request again.'
				)}
			</div>
			{detailsEl}
		</Modal>
	);
}

export function ShellConnected(props: ShellOwnProps) {
	const storeProps = useSlice('shell');
	useClassName(document.body, storeProps.theme, storeProps.colorScheme);

	return <Shell {...props} {...storeProps} />;
}
