import { GetLinkedAccountsResponse } from '../home/models';
import { http } from './hub-utils';

export interface SendVerificationSuccess {
	kind: 'SendVerificationSuccess';
}

export interface SendVerificationFailure {
	kind: 'SendVerificationFailure';
}

export function SendVerificationEmail(sessionToken: string) {
	return http.post<SendVerificationSuccess, SendVerificationFailure>(
		`/api/account/verificationEmail?session_token=${sessionToken}`
	);
}

export function GetLinkedAccounts(state: string) {
	const encodedState = encodeURIComponent(state);
	return http.get<GetLinkedAccountsResponse>(
		`/api/account/linkedAccounts?state=${encodedState}`
	);
}