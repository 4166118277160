import * as Redux from '@reduxjs/toolkit';
import * as ShellSlice from './shell/shell.slice';
import * as HomeSlice from './home/home.slice';
import * as VerifySlice from './verify/verify.slice';
import * as OrganizationsSlice from './user-account-management/organizations/organizations.slice';
import * as AddOrganizationSlice from './user-account-management/organizations/add-organization.slice';

export const slices = {
	[ShellSlice.slice.name]: ShellSlice,
	[HomeSlice.slice.name]: HomeSlice,
	[VerifySlice.slice.name]: VerifySlice,
	[OrganizationsSlice.slice.name]: OrganizationsSlice,
	[AddOrganizationSlice.slice.name]: AddOrganizationSlice,
};

export function createRootReducer() {
	return Redux.combineReducers({
		[ShellSlice.slice.name]: ShellSlice.slice.reducer,
		[HomeSlice.slice.name]: HomeSlice.slice.reducer,
		[VerifySlice.slice.name]: VerifySlice.slice.reducer,
		[OrganizationsSlice.slice.name]: OrganizationsSlice.slice.reducer,
		[AddOrganizationSlice.slice.name]: AddOrganizationSlice.slice.reducer,
	});
}
