import React, { useState, useEffect, createContext, useContext } from "react";
import { AuthContextType, User, UserClaim } from "./auth-context.d";

export const AuthContext = createContext<AuthContextType | null>(null);
export const useAuth = () => useContext(AuthContext) as AuthContextType;

export const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const [userClaims, setUserClaims] = useState<UserClaim[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getUser = async () => {
        const response = await fetch('/auth/getUser');
        const user: User = await response.json();

        setIsAuthenticated(user.isAuthenticated);
        setIsLoading(false);
        if (user.isAuthenticated) setUserClaims(user.claims!);
    }

    useEffect(() => {
        getUser();
    }, []);

    const login = (returnTo?: string) => {
        window.location.href = `/apps/login?returnTo=${returnTo}`;
    }

    const logout = (returnTo?: string) => {
        window.location.href = `/apps/logout?returnTo=${returnTo}`;
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, userClaims, isLoading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
