import * as Redux from '@reduxjs/toolkit';
import { createThunk } from '../util/slice';

export interface StoreProps {
	isEmailVerified: boolean;
	hasSentVerificationEmail: boolean;
	continueUri: string;
	sessionToken: string;
}

export function makeInitialState(): StoreProps {
	const initialData = (window as any)['initialData'];
	const urlParams = new URLSearchParams(window.location.search);
	return {
		isEmailVerified: initialData?.isEmailVerified ?? true,
		hasSentVerificationEmail: false,
		continueUri: initialData?.continueUri ?? '',
		sessionToken: urlParams.get('session_token') ?? ''
	};
}

export const slice = Redux.createSlice({
	name: 'verify',
	initialState: makeInitialState(),
	reducers: {
		sendVerificationEmail(state, action: Redux.Action) {
			state.hasSentVerificationEmail = true;
		}
	},
});

export const thunks = {
	sendVerificationEmail: createThunk(async (args, dispatch) => {
		dispatch(slice.actions.sendVerificationEmail());
	})
};
