import * as Redux from '@reduxjs/toolkit';
import { createRootReducer } from './slices';

let _store: Store;

export function createStore() {
	const store = Redux.configureStore({ reducer: createRootReducer() });
	_store = store;
	return store;
}

export type Store = ReturnType<typeof createStore>;

if (module.hot) {
	module.hot.accept('./slices', async () => {
		const next = await import('./slices');
		_store.replaceReducer(next.createRootReducer());
	});
}

export function getStore(): Store {
	return _store;
}

type NotNull<T> = T extends null ? never : T extends undefined ? never : T;
