import { createHubUtils } from '@dsx/hub-utils';
import { getStore } from '../store';
import { slice } from '../shell/shell.slice';

export const http = createHubUtils({
	postProcess: async (raw) => {
		if (raw.status >= 500 && raw.status <= 599) {
			const store = getStore();
			const error = await raw.text();
			store.dispatch(slice.actions.unhandledErrorOccurred(error));
		}

		return raw;
	},
});
