import * as Redux from '@reduxjs/toolkit';
import { StoreState } from './page';

/** A type which describes the lifecycle of data which must be fetched from a remote resource. */
export type RemoteData<TData, TFailure = void> =
	| { state: 'initial' }
	| { state: 'loading' }
	| { state: 'success'; data: TData }
	| (TFailure extends void
			? { state: 'failure' }
			: { state: 'failure'; failure: TFailure });

export type AppThunk<TArgs> = TArgs extends void
	? () => Redux.ThunkAction<void, StoreState, unknown, Redux.Action<string>>
	: (
			args: TArgs
	  ) => Redux.ThunkAction<void, StoreState, unknown, Redux.Action<string>>;

/**
 * When creating a thunk, this function will be run at the time of dispatch, and provided the args from the caller, Redux's
 * dispatch function, and the getState function.
 */
export type CreateThunkArgs<TArgs> = (
	args: TArgs,
	dispatch: Redux.ThunkDispatch<StoreState, unknown, Redux.Action<string>>,
	getState: () => StoreState
) => Promise<void>;

/**
 * Creates a thunk for Redux to evaluate. Includes arguments, dispatching, state access, network access, and other forms of I/O.
 * By convention, this should dispatch a pending action before beginning remote access, and a success or failure action after completion.
 * @template TArgs the type of args which are provided by the caller.
 * @param io A function which accesses all needed remote data via HTTP, WebSockets, or browser APIs.
 */
export function createThunk<TArgs = void>(
	io: CreateThunkArgs<TArgs>
): AppThunk<TArgs> {
	const argTaker = (args: TArgs) => {
		const thunk: Redux.ThunkAction<
			void,
			StoreState,
			unknown,
			Redux.Action<string>
		> = async (dispatch, getState) => {
			await io(args, dispatch, getState);
		};

		return thunk;
	};

	return argTaker as AppThunk<TArgs>;
}

export async function delay(ms: number) {
	return new Promise<void>((res) => {
		setTimeout(() => res(), ms);
	});
}
