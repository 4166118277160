import { MdChevronRight, StandaloneIcon } from '@dsx-react/icon';
import { Card, DsxLoadingSpinner, Note, Text } from '@dsx/react';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageProps, useSlice } from '../util/page';
import { FiltersConnected } from './filters';
import styles from './home.page.module.css';
import { thunks } from './home.slice';
import { formatApplicationCode } from './homeUtility';

export function Home(props: PageProps<'home'>) {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(thunks.fetchLinkedAccounts());
	}, [dispatch]);

	if (props.isLoading) {
		return (
			<div className={styles['home-container']}>
				<StandaloneIcon
					icon={DsxLoadingSpinner}
					title="Loading"
					size="size-1100"
					color="color-primary-700"
					className={styles['loading-spinner']}
				/>
			</div>
		)
	}

	return (
		<div className={styles['home-container']}>
			{!props.isAccountLinkingComplete && <Note variant='warn' className={styles['error-note']}>You may not see all of your accounts at this time. Please either sign in again or try again later.</Note>}
			{props.isAccountLinkingComplete && props.wasErrorLoadingLinkedAccounts && <Note variant='warn' className={styles['error-note']}>You may not see all of your accounts at this time. Please try again later.</Note>}
			{props.linkedAccounts.length > 1 && <FiltersConnected />}
			{props.filteredAccounts.map(accounts =>
				<div className={styles['application']} key={accounts[0].applicationCode}>
					<Text variant="headline-2">{formatApplicationCode(accounts[0].applicationCode)}</Text>
					<div className={styles['tiles']}>
						{accounts.map(account =>
							<Card
								key={account.accountName}
								bleed="full"
								className={styles['tile']}
								href={account.redirectUrl}
								hrefType='external'>
								<div className={styles['tile-content']}>
									<div className={styles['tile-body']}>
										<div>
											<Text variant="headline-3">{account.supplierName}</Text>
											<Text variant="body">{account.accountName}</Text>
										</div>
									</div>
									<div className={styles['tile-chevron']}>
										<StandaloneIcon
											icon={MdChevronRight}
											size="size-600"
											title="Tile Chevron" />
									</div>
								</div>
							</Card>
						)}
					</div>
				</div>
			)}
			{props.filteredAccounts.length === 0 && <Text variant="headline-2">
				No results found
			</Text>}
		</div>
	);
}

export function HomeConnected() {
	const storeProps = useSlice('home');
	return <Home {...storeProps} />;
}
