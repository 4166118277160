import { combineMessages, Text, useMessageFormatter } from '@dsx/react';
import * as React from 'react';
import commonMessages from '../common.intl';

import pageMessages from './intl';
export function NotFound() {
	const { formatMessage } = useMessageFormatter(
		combineMessages(commonMessages, pageMessages)
	);

	return (
		<div className="flex justify-content-center padding-400">
			<Text variant="light-headline-1">{formatMessage('Page not found.')}</Text>
		</div>
	);
}
