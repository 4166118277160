import { defaultTheme, lightTheme } from '@dsx/react';
import * as Redux from '@reduxjs/toolkit';

export interface StoreProps {
	isErrorModalOpen: boolean;
	errorModalDetails: string | null;
	isLegacyThemeChecked: boolean;
	theme: string;
	isDarkModeChecked: boolean;
	colorScheme: string;
	language: string;
}

export function makeInitialState(): StoreProps {
	return {
		isErrorModalOpen: false,
		errorModalDetails: null,
		isLegacyThemeChecked: false,
		theme: defaultTheme,
		isDarkModeChecked: false,
		colorScheme: lightTheme,
		language: 'en-US',
	};
}

export const slice = Redux.createSlice({
	name: 'shell',
	initialState: makeInitialState(),
	reducers: {
		unhandledErrorOccurred(state, action) {
			state.isErrorModalOpen = true;
			state.errorModalDetails = action.payload;
		},
		unhandledErrorDismissed(state) {
			state.isErrorModalOpen = false;
			state.errorModalDetails = null;
		},
	},
});

export const thunks = {};
