import { Organization } from "../user-account-management/organizations/models";
import { http } from "./hub-utils";

export async function getOrganizations() {
	const response = await http.get<Organization[]>("/api/user-account-management/organizations");

	return response.status === "2xx" ? {
		organizations: response.body,
		status: response.status
	} : {
		status: response.status
	};
}

export async function addOrganization(organization: Organization) {
	// const response = await http.post<Organization>("/api/user-account-management/organizations", organization);

	// return response.status === "2xx" ? {
	// 	organization: response.body,
	// 	status: response.status
	// } : {
	// 	status: response.status
	// };

	// Sleep for 2 seconds to simulate a network request
	await new Promise(resolve => setTimeout(resolve, 2000));

	return {
		organization: organization,
		status: "2xx"
	};
}
