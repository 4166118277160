import { Provider as DsxProvider } from '@dsx/react';
import { LinkSourceProps, LinkSourceRecord, ToastPortal } from '@dsx/react';
import React, { forwardRef, useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom';
import { HomeConnected } from './home/home';
import { NotFound } from './shell/not-found';
import { ShellConnected } from './shell/shell';
import { Store } from './store';
import { VerifyConnected } from './verify/verify';
import { OrganizationsConnected } from './user-account-management/organizations/organizations';
import { AddOrganizationConnected } from './user-account-management/organizations/add-organization';
import { ProtectedRoute } from './auth/protected-route';
import { AuthProvider } from './auth/auth-context';

export interface AppProps {
	appRoot: HTMLElement;
	store: Store;
}

const linkSources = {
	/* eslint-disable react/display-name */
	default: forwardRef<HTMLAnchorElement, LinkSourceProps>((props, ref) => (
		<Link ref={ref} {...props} to={props.href || ''} />
	)),
	external: forwardRef<HTMLAnchorElement, LinkSourceProps>((props, ref) => (
		<a ref={ref} {...props} />
	)),
	none: forwardRef<HTMLSpanElement, LinkSourceProps>((props, ref) => (
		<span ref={ref} {...props} />
	)),
} as LinkSourceRecord;

export function App(props: AppProps) {
	const { store } = props;
	const [locale, setLocale] = useState<string>('en-US');

	useEffect(
		() =>
			store.subscribe(() => {
				const settings = store.getState().shell;
				setLocale(settings.language);
			}),
		[]
	);

	return (
		<DsxProvider locale={locale} linkSources={linkSources} toastPortal={ToastPortal} >
			<ReduxProvider store={store}>
				<BrowserRouter>
					<AuthProvider>
						<ShellConnected>
							<Routes>
								<Route index element={<ProtectedRoute><HomeConnected /></ProtectedRoute>} />
								<Route path="/account/verify" element={<VerifyConnected />} />
								<Route path="/user-account-management" element={<ProtectedRoute><Navigate to="/user-account-management/organizations" /></ProtectedRoute>} />
								<Route path="/user-account-management/organizations" element={<ProtectedRoute><OrganizationsConnected /></ProtectedRoute>} />
								<Route path="/user-account-management/organizations/add" element={<ProtectedRoute><AddOrganizationConnected /></ProtectedRoute>} />
								{/* 
								<Route path="/user-account-management/organizations/:id" element={<ProtectedRoute><Navigate to="/user-account-management/organizations/:id/users" /></ProtectedRoute>} />
								<Route path="/user-account-management/organizations/:id/users" element={<ProtectedRoute><OrganizationUsersConnected  /></ProtectedRoute>} />
								*/}
								<Route path="*" element={<NotFound />} />
							</Routes>
						</ShellConnected>
					</AuthProvider>
				</BrowserRouter>
			</ReduxProvider>
		</DsxProvider>
	);
}
