import React from 'react';
import { StandaloneIcon, DsxLoadingSpinner } from '@dsx/react';
import styles from './protected-route.module.css';
import { useAuth } from './auth-context';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
	const { isLoading, isAuthenticated, login } = useAuth();

	const spinner = () => (
		<div>
			<StandaloneIcon
				icon={DsxLoadingSpinner}
				title="Loading"
				size="size-1100"
				color="color-primary-700"
				className={styles['loading-spinner']}
			/>
		</div>
	);

	if (isLoading)
		return spinner();

	if (!isAuthenticated) {
		login(window.location.pathname);

		return spinner();
    }

    return children;
}
