import * as React from 'react';
import { Text, SelectInput, TextInput } from '@dsx/react';
import { PageProps, useSlice } from '../util/page';
import { formatApplicationCode } from './homeUtility';
import styles from './home.page.module.css';

export function Filters(props: PageProps<'home'>) {
	const { applicationMappedAccounts, updateSearchText, updateApplicationFilter } = props;

	return <div className={styles['filter-container']}>
		<div className="flex flex-direction-column">
			<Text variant="label">Search</Text>
			<TextInput
				onChange={e => updateSearchText(e.currentTarget.value)}
				/>
		</div>
		{applicationMappedAccounts.length > 1 &&
			<div className="flex flex-direction-column">
				<Text variant="label">Filter by</Text>
				<SelectInput
					options={[{ label: 'All accounts', value: 'All' }]
						.concat(applicationMappedAccounts.map(app => {
							return { label: formatApplicationCode(app[0].applicationCode), value: app[0].applicationCode.toString() };
						}))
					}
					onChange={e => updateApplicationFilter(e.currentTarget.value)}
				/>
			</div>
		}
	</div>
}

export function FiltersConnected() {
	const storeProps = useSlice('home');
	return <Filters {...storeProps} />;
}