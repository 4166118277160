import { Button, Text } from '@dsx/react';
import * as React from 'react';
import { SendVerificationEmail } from '../hubs/AccountController.hub';
import { PageProps, useSlice } from '../util/page';
import styles from './verify.page.module.css';

export function Verify(props: PageProps<'verify'>) {
	return (
		<div>
			<Text variant='headline-1'>Verify Your Email Address</Text>
			<div className={styles['verify-body']}>
				<Text variant='body'>Check your email inbox and junk mail folder for instructions.</Text>
				<Text variant='body'>After verifying, click &apos;Continue&apos; below to see your Direct Supply applications.</Text>
			</div>
			<div className={styles['button-container']}>
				{!props.hasSentVerificationEmail &&
					<Button
						variant='default'
						onClick={() => sendVerificationEmail(props.sendVerificationEmail, props.sessionToken)}
						className={styles['resend']}>
							Resend Email
						</Button>}
				{props.hasSentVerificationEmail && <Button variant='default' disabled={true} className={styles['resend']}>Sent</Button>}
				<form action={props.continueUri} method='post'>
					<Button type='submit' variant='primary'>Continue</Button>
				</form>
			</div>
		</div>
	);
}

async function sendVerificationEmail(sendVerificationEmailAction: () => void, sessionToken: string) {
	await SendVerificationEmail(sessionToken);
	sendVerificationEmailAction();
}

export function VerifyConnected() {
	const storeProps = useSlice('verify');
	return <Verify {...storeProps} />;
}
