export type LinkedAccount = {
	accountName: string,
	applicationCode: ApplicationCode,
	applicationUserId: string,
	emailAddress: string,
	redirectUrl: string,
	supplierName: string
}

export type GetLinkedAccountsResponse = {
	linkedAccounts: LinkedAccount[],
	errorLoadingLinkedAccounts: boolean
}

export enum ApplicationCode {
    DSSIConnect = 'DSSIConnect',
    DSENucleus = 'DSENucleus',
    TELSConnect = 'TELSConnect'
}