import { ApplicationCode, LinkedAccount } from "./models";

export function formatApplicationCode(appCode: ApplicationCode) {
	switch (appCode) {
		case ApplicationCode.DSSIConnect:
			return 'DSSI Connect';
		case ApplicationCode.DSENucleus:
			return 'DSE Nucleus';
		case ApplicationCode.TELSConnect:
			return 'TELS Connect';
	}
}

export function filterAccounts(searchText: string, applicationFilter: string, accounts: LinkedAccount[]) {
	let filteredAccounts = [...accounts];

	if (applicationFilter !== 'All') {
		filteredAccounts = filteredAccounts.filter(account => account.applicationCode === applicationFilter);
	}

	searchText = searchText.trim().toLowerCase();
	if (searchText !== '') {
		filteredAccounts = filteredAccounts.filter(account =>
			account.accountName.toLowerCase().indexOf(searchText) >= 0 ||
			account.applicationCode.toLowerCase().indexOf(searchText) >= 0 ||
			formatApplicationCode(account.applicationCode).toLowerCase().indexOf(searchText) >= 0 ||
			account.emailAddress.toLowerCase().indexOf(searchText) >= 0 ||
			(account.supplierName && account.supplierName.toLowerCase().indexOf(searchText) >= 0));
	}

	return mapByApplicationCode(filteredAccounts);
}

export function mapByApplicationCode(linkedAccounts: LinkedAccount[]) {
	const apps = [] as LinkedAccount[][];
	const accountsForSorting = [...linkedAccounts];
	const sortedAccounts = accountsForSorting.sort((a, b) => {
		if (a.applicationCode < b.applicationCode
			|| (a.applicationCode === b.applicationCode && a.accountName < b.accountName)) {
			return -1;
		}
		if (a.applicationCode > b.applicationCode
			|| (a.applicationCode === b.applicationCode && a.accountName > b.accountName)) {
			return 1;
		}
		return 0;
	});

	let previousAppCode = '';
	let i = -1;
	sortedAccounts.forEach(account => {
		const appCode = account.applicationCode;

		if (appCode === previousAppCode) {
			apps[i].push(account);
		} else {
			apps.push([account]);
			previousAppCode = appCode;
			i++;
		}
	});

	return apps;
}

export function getStateParamsFromQueryString() {
	const paramsString = window.location.search;
	const searchParams = new URLSearchParams(paramsString);
	return searchParams.get("state") ?? '';
}