import './global.css';

import '@dsx/react/theme-default/beta';
import '@dsx/react/theme-legacy/beta';
import '@dsx/css-utils';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { createStore, getStore } from './store';
import { App } from './app';

// In our MVC view, we'll attach our React application
// to a div with id="react-root"
const rootEl = document.getElementById('react-root');

if (!rootEl) {
	throw new Error('could not find root element');
}

const store = createStore();

ReactDOM.render(<App appRoot={rootEl} store={store} />, rootEl);

if (module.hot) {
	module.hot.accept('./app', async () => {
		const store = getStore();
		const NextApp = (await import('./app')).App;
		ReactDOM.render(<NextApp appRoot={rootEl} store={store} />, rootEl);
	});

	module.hot.accept();
}
